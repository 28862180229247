import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const LeftEsportazione = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* Price Item for veicolo di proprietà */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              115
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            con veicolo di proprietà
          </p>
        </div>

        {/* Price Item for acquistato da esportatore */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              130
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            acquistato da esportatore
          </p>
        </div>
      </div>
    </div>
  );
};

const RightEsportazione = () => {
  return (
    <div className="w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="text-xl">
        <div className="text-2xl my-4">
          <p>
            Devi esportare un veicolo all’estero? In primis sarà necessario
            richiedere la radiazione per esportazione (effettuata prima
            dell’effettiva esportazione).
          </p>
          <p>
            A termine di questa operazione, verrà emesso un documento unico non
            valido per la circolazione dove verrà annotato il paese di
            esportazione.
          </p>
          <p>
            Si ricorda che non possono essere radiati veicoli con fermi
            amministrativi. In questo caso sarà necessaria un ulteriore
            operazione.
          </p>
        </div>
        <h3 className="text-2xl mb-2 font-roboto font-bold tracking-tight text-gray-900">
          Per poter effettuare la radiazione per esportazione, sono necessari i
          seguenti documenti:
        </h3>

        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">Certificato di proprietà</li>
          <li className="text-gray-700 text-xl">
            Documento unico (DU) o carta di circolazione
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità del proprietario in corso di validità
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di proprietario extracomunitario)
          </li>
          <li className="text-gray-700 text-xl">Targhe del veicolo</li>
        </ul>
      </div>
    </div>
  );
};

export { LeftEsportazione, RightEsportazione };
