import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftEredita, RightEredita } from '../../components/pratiche-auto/eredita-veicolo/EreditaVeicolo';
import { LeftEsportazione, RightEsportazione } from '../../components/pratiche-auto/esportazione-veicolo/EsportazioneVeicolo';

const EsportazioneVeicolo = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="esportazione-veicolo" title="Esportazione Veicolo">
                <LeftEsportazione />
                <RightEsportazione />

            </ContentPratica>

        </Layout>
    )
}

export default EsportazioneVeicolo
